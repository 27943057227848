import request from '@/utils/cydsrequst';

export const face = (imgFile) => {
  return request({
    url: '/api/blade-policy/ocr/face',
    method: 'post',
    params: {
      imgFile
    }
  })
};

export const back = (imgFile) => {
  return request({
    url: '/api/blade-policy/ocr/back',
    method: 'post',
    params: {
      imgFile
    }
  })
};

export const humanFace = (faceFile, imgFile) => {
  return request({
    url: '/api/blade-policy/ocr/humanFace',
    method: 'post',
    params: {
      faceFile,
      imgFile
    }
  })
};

// 营业执照识别
export const license = (imgFile) => {
  return request({
    url: '/api/blade-policy/ocr/license',
    method: 'post',
    params: {
      imgFile,
    }
  })
};


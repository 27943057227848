<template>
  <div>
    <el-upload :auto-upload="true" :action="uploadUrl" ref="upload" :on-success="fnUploadSuccess"
      :on-remove="handleRemove" :http-request="fnUploadRequest" :before-upload="beforeUpload" :disabled="disabled"
      :limit="1" list-type="picture-card" accept=".jpg, .jpeg, .png, .gif" :show-file-list="false" :multiple="false"
      v-if="path === ''">
      <el-button size="small" type="primary" v-show="!disabled ">点击上传
      </el-button>
      <!-- <div slot="tip" class="el-upload__tip">只能上传jpg/png文件</div> -->
    </el-upload>
    <div v-if="path !== ''" class="el-upload-list el-upload-list--picture-card">
      <div class="el-upload-list__item is-success">
        <img class="el-upload-list__item-thumbnail" :src="ossPath" />
        <label class="el-upload-list__item-status-label">
          <i class="el-icon-upload-success el-icon-check"></i>
        </label>
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" v-show="!disabled ">
            <i class="el-icon-delete" @click="handleRemove()"></i>
          </span>
          <span class="el-upload-list__item-preview">
            <i class="el-icon-zoom-in" @click="handlePreview()"></i>
          </span>
        </span>
      </div>
    </div>

    <el-dialog v-model="imageDialog2" append-to-body>
      <img width="100%" style="width:100%;" :src="ossPath" alt="" />
    </el-dialog>

  </div>
</template>
<script>

import { face, back, humanFace } from "@/api/ocr/ali-oss";
import { client, getObjectUrl, objectToBuffer } from "@/utils/ali-oss";
import { compressImage } from "@/utils/compressImage";
export default {
  name: 'SingleUpload',
  props: {
    // 上传图片类型
    imageType: {
      type: String,
      default: function () {
        return '0'
      }
    },
    // 是否展示查看层
    imageDialog: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 是否禁用上传按钮
    disabled: {
      type: Boolean,
      default: function () {
        return false
      }
    },
    // 上传图片路径
    path: {
      type: String,
      default: function () {
        return ''
      }
    },
    // 身份证正面图片路径
    facePath: {
      type: String,
      default: function () {
        return ''
      }
    },
    // 学员类型
    stuType: {
      type: Number,
      default: function () {
        return ''
      }
    },
    // 学员id
    studentId: {
      type: String,
      default: function () {
        return ''
      }
    }
  },
  data () {
    return {
      data: {},
      uploadUrl: '',
      limit: 10,
      disabled: false,
      path2: '',
      imageDialog2: false,
    }
  },
  computed: {
    // 选择的没有班级的学员ids
    ossPath () {
      console.log(getObjectUrl(this.path))
      return getObjectUrl(this.path);
    },
  },
  methods: {

    handlePreview () {
      console.log(getObjectUrl(this.path))
      this.imageDialog2 = true;
    },
    getUUID () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
      })
    },
    getNowFormatDate () {
      //获取当月时间 yyyy-MM-dd
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = '0' + month;
      }
      if (strDate >= 1 && strDate <= 9) {
        strDate = '0' + strDate;
      }
      return year + '-' + month + '-' + strDate;
    },
    handleRemove () {
      if (this.path !== undefined && this.path !== '') {
        // 删除oss上的照片
        try {
          client().delete(this.path);
        } catch (e) {
          console.log(e);
        }
        this.data = {};
        this.path2 = '';
        this.$emit("dataChange", this.path2, this.imageType, this.data);
      }

    },
    // 自定义实现上传方式
    fnUploadRequest (option) {
      try {
        let file = option.file
        let date = this.getNowFormatDate();
        let fileNames = this.getUUID() + file.name.substring(file.name.lastIndexOf("."));
        let filePath = `upload/${date}/${fileNames}`;
        let that = this
        // 如果文件大于3M，那么进行压缩上传
        if (file.size >= 1024 * 1024 * 3) {
          let config = {
            width: 856, // 压缩后图片的宽
            height: 540, // 压缩后图片的高
            quality: 1 // 压缩后图片的清晰度，取值0-1，值越小，所绘制出的图像越模糊
          }
          compressImage(file, config).then(result => {
            let then = this;
            let reader2 = new FileReader();
            reader2.readAsArrayBuffer(result);
            reader2.onload = function (event) {
              // arrayBuffer转Buffer
              let buffer = objectToBuffer(event.target.result);
              client().put(filePath, buffer).then(ret => {
                console.log(ret)
                if (ret.name) {
                  // 获得图片地址
                  then.path2 = ret.name;
                  option.onSuccess();
                }
              });
            }
          });
        } else {
          client().put(filePath, file).then(ret => {
            console.log(ret)
            console.log(that.path)
            if (ret.name) {
              // 获得图片地址
              that.path2 = ret.name;
              option.onSuccess();
            }
          });
        }



      } catch (error) {
        this.disabled = false
        option.onError('上传失败')
      }
    },

    // 文件上传成功的函数
    async fnUploadSuccess () {
      this.$emit("dataChange", this.path2, this.imageType, this.data);
      // let distinguish = this.distinguish(this.ossPath);
      // distinguish.then((data) => {
      //   if (data.flag) {
      //     this.$emit("dataChange", this.path2, this.imageType, this.data);
      //   } else {
      //     this.$message({
      //       type: "error",
      //       message: data.msg
      //     });
      //     this.handleRemove();
      //   }
      // }, (err) => {
      //   this.$message({
      //     type: "error",
      //     message: "操作失败"
      //   });
      //   this.handleRemove();
      // })
    },
    // 文件上传之前的函数
    beforeUpload () {
      let isType = true;
      if (this.imageType === '1') {
        if (this.facePath === undefined || this.facePath === '') {
          isType = false;
        }
        // if (!isType) {
        //   this.$message({
        //     type: "error",
        //     message: "请先上传身份证正面，在上传人脸照片"
        //   });
        // }
      }
      isType = true;
      if (this.imageType === '2') {
        if (this.stuType === undefined) {
          isType = false;
        }
        if (!isType) {
          this.$message({
            type: "error",
            message: "请先选择人员类型"
          });
        }
      }
      return isType;
    },
    // 根据文件类型调用不同的接口
    distinguish (fileName) {
      return new Promise((resolve) => {
        if (this.imageType === '2') {
          // 识别身份证正面
          // 前后端提交post异步请求获取签名信息
          face(fileName).then((res) => {
            if (res.data.success === true) {
              let data = res.data.data;
              if (data.idNumber === undefined) {
                resolve(false, "身份证识别失败，请重新上传")
              }

              this.data.idNumber = data.idNumber;
              this.data.nationality = data.nationality;
              this.data.sex = data.sex;
              this.data.birthDate = data.birthDate;
              this.data.site = data.address;
              this.data.provinceCode = data.domicileProvinceCode;
              this.data.cityCode = data.cityCode;
              this.data.districtCode = data.domicileDistrictCode;
              this.data.stuName = data.stuName;
              this.data.age = data.age;
              this.data.postal = data.postal;

              resolve({ flag: true, msg: "识别成功" });
            } else {
              resolve({ flag: false, msg: res.data.msg });
            }
          });
        } else if (this.imageType === '1') {
          // 人脸识别

          humanFace(getObjectUrl(this.facePath), fileName).then((res) => {
            let date = res.data.data;
            if (res.data.success === true) {
              resolve({ flag: true, msg: "识别人脸成功" });
            } else {
              resolve({ flag: false, msg: res.data.msg });
            }
          })
          // resolve(true,"识别人脸成功")
        } else {
          // 其他情况
          resolve({ flag: true, msg: "识别成功" });
        }

      })
    }
  },
}

</script>

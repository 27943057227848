<template>
  <n-form :model="formModal" ref="formRef" :rules="formModalRules" label-width="80px" require-mark-placement="left"
    label-placement="left" size="large" class="mt-1">
    <n-form-item label="身份证号" path="identityNumber" ref="phoneRef">
      <n-input placeholder="请输入身份证号" v-model:value="formModal.identityNumber" />
    </n-form-item>
    <n-form-item first path="password" label="密码">
      <n-input v-model:value="formModal.password" type="password" />
    </n-form-item>
    <div class="flex justify-end">
      <n-button :loading="saveLoading" class="px-10" type="primary" @click="submit">
        登录
      </n-button>
    </div>
  </n-form>
</template>

<script setup>
import { reactive, ref, defineEmits } from 'vue';
import { sendCodeApi, validate } from '@/api';
import { registerApi, loginApi } from '@/api/cyds';
import { useStore } from 'vuex';
const store = useStore();
const emit = defineEmits(['close']);

const formModalRules = {
  account: [
    // {
    //   required: true,
    //   trigger: ['input', 'blur'],
    //   message: '请输入用户名',
    //   whitespace: true,
    // },
    {
      pattern: /^[a-zA-Z0-9]{4,16}$/,
      message: '用户名只能为字母或者数字（长度4~16）',
      trigger: ['input', 'blur'],
    },
  ],
  phone: [
    {
      required: true,
      trigger: ['input', 'blur'],
      message: '请输入手机号',
      whitespace: true,
    },
    {
      pattern: /^1[3456789]\d{9}$/,
      message: '手机格式输入错误，请重新输入',
      trigger: ['input', 'blur'],
    },
  ],
  verificationCode: {
    required: true,
    trigger: ['input', 'blur'],
    message: '请输入验证码',
    whitespace: true,
  },
  password: [
    {
      required: true,
      whitespace: true,
      trigger: ['input', 'blur'],
      message: '请输入密码',
    },
    {
      trigger: ['input', 'blur'],
      min: 6,
      max: 20,
      message: '密码长度为6~20个字符',
    },
  ],
  password2: [
    {
      required: true,
      whitespace: true,
      trigger: ['input', 'blur'],
      message: '请再次输入密码',
    },
    {
      trigger: ['input', 'blur'],
      min: 6,
      max: 20,
      message: '密码长度为6~20个字符',
    },
    {
      validator: validatePasswordStartWith,
      message: '两次密码输入不一致',
      trigger: ['input', 'blur'],
    },
  ],
  identityNumber: [
    {
      required: true,
      trigger: ['input', 'blur'],
      message: '请输入身份证号',
      whitespace: true,
    },
    {
      pattern:
        /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
      message: '身份证号格式输入错误，请重新输入',
      trigger: ['input', 'blur'],
    },
  ],
  realName: {
    required: true,
    trigger: ['input', 'blur'],
    message: '请输入真实姓名',
    whitespace: true,
  },
};

function validatePasswordStartWith (rule, value) {
  return (
    formModal.password &&
    formModal.password.startsWith(value) &&
    formModal.password.length >= value.length
  );
}

const codeLoading = ref(false);
const saveLoading = ref(false);
const verifyTime = ref(0);
const phoneRef = ref(null);
const formRef = ref(null);
const formModal = reactive({
  account: '',
  password: '',
  identityNumber: '',
});

function sendCode () {
  phoneRef.value.validate({
    trigger: 'blur',
    async callback (errors) {
      console.log(errors);
      if (errors) {
        return;
      }
      codeLoading.value = true;
      try {
        const res = await sendCodeApi(formModal.phone);
        codeLoading.value = false;
        if (res.success) {
          window.message.success('发送成功');
          // 发送验证码
          verifyTime.value = 60;
          let verifyTimer = setInterval(() => {
            if (verifyTime.value === 0) {
              clearInterval(verifyTimer);
              return;
            }
            verifyTime.value--;
          }, 1000);
        } else {
          throw new Error('发送失败');
        }
      } catch (err) {
        window.message.error(err || '修改失败');
        codeLoading.value = false;
      }
    },
  });
}

function yanzheng () {
  const row = 'idCardNo=' + formModal.identityNumber + '&name=' + formModal.realName;
  console.log(row);
  validate(row).then(res => {
    if (res.code !== undefined && res.data.desc === '一致') {
      console.log(res);
      fn1();
    } else if ((res.code === 200 && res.data.desc === '无记录') || res.data.desc === '不一致') {
      console.log(res);
      window.message.error('身份证和姓名不一致');
    } else {
      console.log(res);
      window.message.error(res.data.msg);
    }
  });
}

async function fn1 () {
  saveLoading.value = true;
  formModal.account = formModal.identityNumber
  try {
    const res = await loginApi(formModal);
    console.log(res)
    if (res.success) {
      if (res.msg === '操作成功') {
        store.commit('SET_CYDS_TOKEN', res.data.accessToken);
        emit('close');
        window.location.reload(true);
      } else {
        window.message.error(res.data.msg);
      }
      return
    }
    if (res.data.success) {
      if (res.msg === '操作成功') {
        store.commit('SET_CYDS_TOKEN', res.data.accessToken);
        emit('close');
        window.location.reload(true);
      } else {
        window.message.error(res.data.msg);
      }
    } else {
      window.message.error(res.data.msg);
    }
  } catch (err) {
    window.message.error(err || '登录失败');
  }
  saveLoading.value = false;
}

function submit (e) {
  e.preventDefault();
  formRef.value.validate(async errors => {
    if (errors) {
      return;
    }
    fn1()
    // yanzheng();
    // saveLoading.value = true;
    // try {
    //   const res = await registerApi(formModal);
    //   if (res.success) {
    //     if (res.msg === 'SUCCESS') {
    //       window.message.success('注册成功 请下载APP进行扫码进行登录', { duration: 3000 });
    //       emit('close');
    //     } else {
    //       window.message.error(res.msg);
    //     }
    //   } else {
    //     throw new Error(res.msg);
    //   }
    // } catch (err) {
    //   window.message.error(err || '注册失败');
    // }
    // saveLoading.value = false;
  });
}
</script>

<template>
  <div class="common-layout">
    <div class="warps">
      <div class="logo">
        <img src="https://qjck.oss-cn-shanghai.aliyuncs.com/cyds/img/logo.png" alt="">
      </div>
      <div class="header_nav">
        <div :span="3">
          <router-link to="/cyds/home">首页</router-link>
        </div>
        <div :span="3">
          <el-link class="fz18">
            <!-- <router-link> -->
            关于大赛
            <!-- </router-link> -->
          </el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">新闻中心</el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">参赛指南</el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">支持政策</el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">大赛服务</el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">往届回顾</el-link>
        </div>
        <div :span="3">
          <el-link class="fz18">联系我们</el-link>
        </div>

      </div>
      <template v-if="!isLogin">
        <n-button type="primary" size="large" style='margin-right:10px;' @click="showLoginModal = true">登录</n-button>
        <n-button type="primary" size="large" @click="showRegisterModal = true">注册</n-button>
      </template>
      <template v-if="isLogin">
        <div class="names">{{stuinfo.stuName||stuinfo.idNumber||'用户'}}</div>
        <n-button type="primary" size="large" @click="outlogin">退出登录</n-button>
      </template>

    </div>
    <!-- -->

    <!-- 
        
        <router-link :to="0 || '/cyds/signup'">
          我要报名
        </router-link> -->
    <n-modal v-model:show="showRegisterModal" :mask-closable="false" :bordered="false" preset="card" title="注册"
      style="width: 500px">
      <Register @close="showRegisterModal = false" />
    </n-modal>
    <n-modal v-model:show="showLoginModal" :mask-closable="false" :bordered="false" preset="card" title="登录"
      style="width: 500px">
      <Login @close="showLoginModal = false" />
    </n-modal>
  </div>
  <el-dialog v-model="centerDialogVisible" :show-close='false' :close-on-press-escape='false'
    :close-on-click-modal='false' title="请进行实名认证" width="70%" :before-close='noguanbi' destroy-on-close center>
    <div>
      <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: center">

        <el-col :span="8">
          <el-tag effect="dark">
            身份证正面
          </el-tag>
        </el-col>
        <el-col :span="8">
          <el-tag effect="dark">
            身份证反面
          </el-tag>
        </el-col>
        <el-col :span="8">
          <el-tag effect="dark">
            个人照片
          </el-tag>
        </el-col>
      </el-row>
      <el-row :gutter="24" justify="center" style="margin-top: 30px;margin-bottom: 10px;text-align: center">

        <el-col :span="8">
          <SingleUploadFace @dataChange="dataChange"
            v-bind:disabled="(addData.identityUpPhoto !== undefined && addData.identityUpPhoto !== '') && (isEdit || formDisable)"
            v-bind:stuType="addData.type" v-bind:path="addData.identityUpPhoto" v-bind:imageType="'2'">
          </SingleUploadFace>
        </el-col>
        <el-col :span="8">
          <SingleUploadFace @dataChange="dataChange"
            v-bind:disabled="(addData.identityUpPhoto !== undefined && addData.identityUpPhoto !== '') && (isEdit || formDisable)"
            v-bind:stuType="addData.type" v-bind:path="addData.identityDownPhoto" v-bind:imageType="'3'">
          </SingleUploadFace>
        </el-col>
        <el-col :span="8">
          <SingleUploadFace @dataChange="dataChange"
            v-bind:disabled="(addData.identityUpPhoto !== undefined && addData.identityUpPhoto !== '') && (isEdit || formDisable)"
            v-bind:stuType="addData.type" v-bind:path="addData.personalPhoto" v-bind:imageType="'1'">
          </SingleUploadFace>
        </el-col>
      </el-row>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="RacePhotos">提交</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {
  ref,
  nextTick,
  onMounted,
  onBeforeUnmount,
  computed,
  reactive,
  defineEmit,
  useContext,
  watch,
} from 'vue';
import Register from './Register.vue';
import SingleUploadFace from "@/components/single-upload/mainFace";
import { ElMessage } from 'element-plus'
import Login from './Login.vue';
import { useStore } from 'vuex';
import { getUserInfoApi, uploadRacePhotos } from '@/api/cyds';
const store = useStore();
const isLogin = computed(() => Boolean(store.state.cydstoken));
const showRegisterModal = ref(false)
const showLoginModal = ref(false)
const studentMessage = reactive({});
const centerDialogVisible = ref(false)
const noguanbi = function (done) {
  console.log(12)
  // ElMessageBox.confirm('Are you sure to close this dialog?')
  //   .then(() => {
  //     done()
  //   })
  //   .catch(() => {
  //     // catch error
  //   })
}
const addData = reactive({
  type: 1,
  personalPhoto: '',//个人照片
  identityUpPhoto: '',//身份证正面
  identityDownPhoto: '',//身份证反面
})
const dataChange = (params, imageType, data) => {
  console.log(params)

  if (imageType === '1') {
    addData.personalPhoto = params;
  } else if (imageType === '2') {
    addData.identityUpPhoto = params;
  } else if (imageType === '3') {
    addData.identityDownPhoto = params;
  } else if (imageType === '4') {
    addData.diplomaDownPhoto = params;
  } else if (imageType === '5') {
    addData.diplomaUpPhoto = params;
  } else if (imageType === '6') {
    addData.positionUpPhoto = params;
  } else if (imageType === '7') {
    addData.positionDownPhoto = params;
  } else if (imageType === '8') {
    addData.entrepreneurshipCertificate = params;
  }
}
const RacePhotos = () => {

  if (!addData.identityUpPhoto) {
    ElMessage({
      type: "error",
      message: "请先上传身份证图片"
    });
    return
  }
  if (!addData.identityDownPhoto) {
    ElMessage({
      type: "error",
      message: "请先上传身份证图片"
    });
    return
  }
  if (!addData.personalPhoto) {
    ElMessage({
      type: "error",
      message: "请先选择人员照片"
    });
    return
  }
  uploadRacePhotos({
    faceFile: addData.personalPhoto,
    identityDownPhoto: addData.identityDownPhoto,
    identityUpPhoto: addData.identityUpPhoto
  }).then(res => {
    console.log(res)
    if (res.code == 200) {
      ElMessage({
        type: "success",
        message: res.data.msg
      });
      window.location.reload(true);
    } else {
      ElMessage({
        type: "error",
        message: res.data.msg
      });
    }
  })
}
function outlogin () {
  store.commit('SET_CYDS_TOKEN');
  window.location.reload(true);
}
const stuinfo = ref({})
onMounted(() => {
  console.log('!!!!!!!!!!!!!');
  const isLogin_deng = computed(() => Boolean(store.state.cydstoken));
  console.log(isLogin_deng.value);
  if (isLogin_deng.value) {
    getUserInfoApi()
      .then(res => {
        if (res) {
          console.log(res)
          if (res.data) {
            store.commit('SET_STUINFO', res.data);
            stuinfo.value = res.data
            if (!res.data.identityDownPhoto) {
              centerDialogVisible.value = true
            }
          }
        }
      })
    // .then(res => {
    //   console.log(res);
    //   Object.assign(studentMessage, res);
    //   const { stuName, photo } = res;
    //   formModel_deng.stuName = stuName;
    //   formModel_deng.proof = photo;
    // });
  }
});
</script>

<style>
.warps {
  width: 1200px;
  margin: 0 auto;
  height: 123px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  width: 216px;
  height: 89px;
}
.header_nav {
  width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}
.fz18 {
  font-size: 18px;
}
.names {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: right;
}
</style>
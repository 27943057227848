import request from '@/utils/cydsrequst';
// 性别
export const dictionariesSex = () =>
  request({
    url: '/api/blade-system/dict/dictionary?code=sex',
    method: 'get',
  })
export const loginApi = data =>
  request({
    url: '/api/blade-auth/token',
    method: 'post',
    params: {
      ...data,
      grantType: 'password',
      tenantId: 'DEPTID:RACEENLIST',
    },
  });
// 注册
export const registerApi = data =>
  request({
    url: '/api/blade-app/raceEnlist/register',
    method: 'post',
    data,
  });
//获取用户信息
export const getUserInfoApi = data =>
  request({
    url: '/api/blade-app/raceEnlist/getStuInfo',
    method: 'get',
    data,
  });
//企业报名-详情
export const detailEnterprise = data =>
  request({
    url: '/api/blade-app/raceEnlist/detailEnterprise',
    method: 'get',
    params: {
      ...data
    },
  });
//上传身份证正反面和一寸照片(头像)
export const uploadRacePhotos = data =>
  request({
    url: '/api/blade-app/raceEnlist/uploadRacePhotos',
    method: 'get',
    params: {
      ...data
    },
  });
//获取单位和赛区的两级下拉
export const getRaceArea = data =>
  request({
    url: '/api/blade-app/raceEnlist/getRaceArea',
    method: 'get',
    params: {
      ...data
    },
  });
//获取单位和赛区的两级下拉
export const submitEnterprise = data =>
  request({
    url: '/api/blade-app/raceEnlist/submitEnterprise',
    method: 'post',
    data,
  });
//1执行摘要-详情
export const getdetailExecute = data =>
  request({
    url: '/api/blade-app/racePlan/detailExecute',
    method: 'get',
    params: {
      ...data
    },
  });
//执行摘要-新增或修改
export const submitExecute = data =>
  request({
    url: '/api/blade-app/racePlan/submitExecute',
    method: 'post',
    data,
  });
//市场分析-详情
export const getetailMarket = data =>
  request({
    url: '/api/blade-app/racePlan/detailMarket',
    method: 'get',
    params: {
      ...data
    },
  });
//市场分析--新增或修改
export const submitMarket = data =>
  request({
    url: '/api/blade-app/racePlan/submitMarket',
    method: 'post',
    data,
  });


//营销策略
export const detailManagement = data =>
  request({
    url: '/api/blade-app/racePlan/detailManagement',
    method: 'get',
    params: {
      ...data
    },
  });
//团队详情
export const listTeam = data =>
  request({
    url: '/api/blade-app/racePlan/listTeam',
    method: 'get',
    params: {
      ...data
    },
  });
//启动资金-来源详情
export const detailMoneySource = data =>
  request({
    url: '/api/blade-app/racePlan/detailMoneySource',
    method: 'get',
    params: {
      ...data
    },
  });
//最近年度利润-详情
export const detailMoneyHistory = data =>
  request({
    url: '/api/blade-app/racePlan/detailMoneyHistory',
    method: 'get',
    params: {
      ...data
    },
  });
//利润预测-详情
export const detailMoneyFuture = data =>
  request({
    url: '/api/blade-app/racePlan/detailMoneyFuture',
    method: 'get',
    params: {
      ...data
    },
  });
//融资需求-详情
export const detailFinancing = data =>
  request({
    url: '/api/blade-app/racePlan/detailFinancing',
    method: 'get',
    params: {
      ...data
    },
  });
//风险分析与对策-详情
export const detailRisk = data =>
  request({
    url: '/api/blade-app/racePlan/detailRisk',
    method: 'get',
    params: {
      ...data
    },
  });
//企业愿景-详情
export const detailVision = data =>
  request({
    url: '/api/blade-app/racePlan/detailVision',
    method: 'get',
    params: {
      ...data
    },
  });


//营销策略-新增或修改
export const submitManagement = data =>
  request({
    url: '/api/blade-app/racePlan/submitManagement',
    method: 'post',
    data,
  });
//管理团队-新增或修改
export const submitTeam = data =>
  request({
    url: '/api/blade-app/racePlan/submitTeam',
    method: 'post',
    data,
  });
//启动资金-新增或修改
export const submitMoneySource = data =>
  request({
    url: '/api/blade-app/racePlan/submitMoneySource',
    method: 'post',
    data,
  });


//最近年度利润-新增或修改
export const submitMoneyHistory = data =>
  request({
    url: '/api/blade-app/racePlan/submitMoneyHistory',
    method: 'post',
    data,
  });
//利润预测新增或修改
export const submitMoneyFuture = data =>
  request({
    url: '/api/blade-app/racePlan/submitMoneyFuture',
    method: 'post',
    data,
  });
//融资需求-明细新增或修改
export const submitPlanFinancingDetail = data =>
  request({
    url: '/api/blade-app/racePlan/submitPlanFinancingDetail',
    method: 'post',
    data,
  });
//融资需求-明细新增或修改
export const submitRisk = data =>
  request({
    url: '/api/blade-app/racePlan/submitRisk',
    method: 'post',
    data,
  });
//企业愿景-新增或修改
export const submitVision = data =>
  request({
    url: '/api/blade-app/racePlan/submitVision',
    method: 'post',
    data,
  });

// 6融资需求-新增或修改
export const submitFinancing = data =>
  request({
    url: '/api/blade-app/racePlan/submitFinancing',
    method: 'post',
    data,
  });
//导师-详情
export const detailTutor = data =>
  request({
    url: '/api/blade-app/raceEnlist/detailTutor',
    method: 'get',
    params: {
      ...data
    },
  });
// 导师-新增或修改
export const submitTutor = data =>
  request({
    url: '/api/blade-app/raceEnlist/submitTutor',
    method: 'post',
    data,
  });
//返乡农工-新增或修改
export const submitFarmer = data =>
  request({
    url: '/api/blade-app/raceEnlist/submitFarmer',
    method: 'post',
    data,
  });
//返乡农工-详情
export const detailFarmer = data =>
  request({
    url: '/api/blade-app/raceEnlist/detailFarmer',
    method: 'get',
    params: {
      ...data
    },
  });

export const confirmPlan = data =>
  request({
    url: '/api/blade-app/raceEnlist/confirmPlan',
    method: 'post',
    params: {
      ...data
    },
  });
import axios from 'axios';
import router from '@/router';
import { getCydsToken, removeCydsToken } from './auth';

function setToken (config) {
  const token = getCydsToken();
  config.headers['Authorization'] = 'Basic ' + 'c2FiZXI6c2FiZXJfc2VjcmV0';
  if (token) {
    config.headers['blade-auth'] = 'bearer ' + token;
  } else {
    console.log(config.url)
    if (config.url === '/api/blade-auth/token') {
      config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
      // console.log('$$$$$$$$$$$$$$$');
      // console.log(config);
    }
  }
}

const service = axios.create({
  timeout: 1000 * 60 * 5,
  withCredentials: true,
  validateStatus (status) {
    return status >= 200 && status <= 500;
  },
  baseURL: process.env.NODE_EVN === 'production' ? process.env.VUE_APP_REQUEST_BASE_URL : '/',
});

service.interceptors.request.use(
  config => {
    setToken(config);
    return config;
  },
  error => Promise.reject(error),
);

service.interceptors.response.use(
  res => {
    // if (res.status !== 200) {
    //   return Promise.reject('请求错误');
    // }
    if (res.data.code === 401) {
      removeCydsToken()
      location.reload();
      window.message.error('登录超时,请重新登录');
      router.push('/');
    }
    if (res.data.msg === '登录用户不能为空') {
      removeCydsToken()
      location.reload();
      window.message.error('登录超时,请重新登录');
      router.push('/');
    }
    if (res.data.code && res.data.code !== 200) {
      window.message.error(res.data.msg);
      // return Promise.reject(res.data.msg);
      return res;
    }

    return res.data;
  },
  error => Promise.reject(error.message),
);

export default service;
